import React, { useState } from 'react'
import './Home.css'
import Fade from 'react-reveal/Fade'
import { Link } from 'react-scroll'
import Particles from 'react-particles-js'
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle'
import Navbar from '../navbar/Navbar'
import config from '../../config'
import Elkoury from '../../images/Elkoury.jpg'
import Typewriter from 'typewriter-effect'
import linkedin from '../../images/social/linkedin.png'

const Home = () => {
  const [imageLoaded, setImageLoaded] = useState(true)
  return (
    <div className="home-wrapper">
      <div className="home">
        <Particles
          className="particles"
          params={config.particles}
        />
        <div className={`greeting${!imageLoaded ? ' hide' : ''}`}>
          <Fade bottom distance="40px">
            <img
              className="Elkoury"
              alt="Elkoury"
              src={Elkoury}
              onLoad={() => setImageLoaded(true)}
            />
            <h1 className="greeting-text_1">
              {/* Hi, I'm <span className="name">Elkoury VOUDED</span>.{' '} */}
              Bonjour, je suis <span className="name">Elkoury VOUDED</span>.{' '}
              <span className="wave-emoji" role="img" aria-label="waving hand">👋</span>
            </h1>
            {/* <h1 className="greeting-text">I'm a Mechatronics and Robotics engineer.</h1> */}
            <h1 className="greeting-text_2">Je suis ingénieur en Mécatronique et Robotique.</h1>
            <h2 className="greeting-text_typewriter">
              <Typewriter
                options={{
                  strings: [
                    "Je suis passionné par les nouvelles technologies.",
                    "Rencontrer de nouvelles personnes m'inspire.",
                    "Je conçois des systèmes mécatroniques et robotiques innovants.",
                  ],
                  autoStart: true,
                  loop: true,
                  deleteSpeed: 10,
                  cursor: '<',
                  delay: 100,
                }}
              />
            </h2>
            <div className="linkedIn">
                <a
                  href="https://www.linkedin.com/in/elkouryvouded/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedin} alt="Linkedin Logo"/>
                </a>
            </div>
            <div className="scroll-down">
              <Link
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={-63}
                duration={500}
              >
                <ArrowDropDownCircleIcon
                  fontSize="large"
                  style={{ color: 'white', pointerEvents: 'fill', cursor: 'pointer' }}
                />
              </Link>
            </div>
          </Fade>
        </div>
        <Navbar />
      </div>
    </div>
  )
}

export default Home

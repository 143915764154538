import React from 'react';
import './App.css';
import Home from './components/home/Home';
import About from './components/about/About';
import Experience from './components/experience/Experience';
import Education from './components/education/Education';
import Footer from './components/footer/Footer';
import TopButton from './components/topButton/TopButton';

function App() {
  return (
      <div className="App">
        <Home />
        <About />
        <Experience />
        <Education />
        <Footer />
        <TopButton />
      </div>
  );
}

export default App;

import React from 'react'
import './Education.css'
import { Fade } from 'react-reveal'
import Section from '../section/Section'
import EducationCard from '../educationCard/EducationCard'
import educationData from '../../data/education.json'

const Education = () => {
  return (
    <Section title="Education" titre="Formation">
      <div className="education-content">
        <ul className="education-list">
          {educationData.education.map((exp) => (
            <li key={`education-${exp.school}`}>
              <Fade bottom duration={1000} distance="20px">
                <EducationCard education={exp} />
              </Fade>
            </li>
          ))}
        </ul>
      </div>
    </Section>
  )
}

export default Education
import React, { useState } from 'react';
import './Experience.css';
import { Fade } from 'react-reveal';
import Section from '../section/Section';
import ExperienceCard from '../experienceCard/ExperienceCard';
import experienceData from '../../data/experience.json';

const Experience = () => {
  const initialRowsToShow = 3;
  const [rowsToShow, setRowsToShow] = useState(initialRowsToShow);

  const handleShowMore = () => {
    setRowsToShow(experienceData.experience.length);
  };

  const handleShowLess = () => {
    setRowsToShow(initialRowsToShow);
  };

  return (
    <Section title="Experience" titre="Expérience">
      <div className="experience-content">
        <ul className="experience-list">
          {[...experienceData.experience].slice(0, rowsToShow).map((exp) => (
            <li key={`experience-${exp.id}`}>
              <Fade bottom duration={1000} distance="20px">
                <ExperienceCard experience={exp} />
              </Fade>
            </li>
          ))}
        </ul>
        {experienceData.experience.length > initialRowsToShow && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              marginTop: '10px',
            }}
          >
            {rowsToShow === initialRowsToShow ? (
              <div onClick={handleShowMore}>
                <p style={{ textAlign: 'center', display: 'inline-block', marginRight: '10px' }}>
                  Voir plus
                </p>
                <span className="arrow-icon" style={{ fontSize: '20px', display: 'inline-block' }}>
                  ↓
                </span>
              </div>
            ) : (
              <div onClick={handleShowLess}>
                <p style={{ textAlign: 'center', display: 'inline-block', marginRight: '10px' }}>
                  Voir moins
                </p>
                <span className="arrow-icon" style={{ fontSize: '20px', display: 'inline-block' }}>
                  ↑
                </span>
              </div>
            )}
          </div>
        )}
        <Fade bottom duration={1200} distance="20px">
          <div className="experience-plus-paragraph" >
            <p>
              Pour plus de détails, notamment pour les expériences antérieures à 2020,
              <br />
              vous pouvez consulter mon profil {' '}
              <a
                href="https://www.linkedin.com/in/elkouryvouded/"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: 'none',
                  color: '#35c9cc',
                  cursor: 'pointer',
                }}
              >
                LinkedIn
              </a>
              .
            </p>
          </div>
        </Fade>
      </div>
    </Section>
  );
};

export default Experience;

import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import App from './App';

function Routing() {
  return (
      <div className="App">
        <Routes>
          <Route path="/" element={<App />} />
        </Routes>
      </div>
  );
}

export default Routing;
import React from 'react'
import './EducationCard.css'
import { Link } from 'react-router-dom'

const EducationCard = ({ education }) => {
  let { link, school, title, diploma, dateFrom, dateTo, location, info} = education
  return (
        <Link to={link} className="education-link">
      <div className="education-card-wrapper">
        <div className="education-card">
          <div className="education-card-top">
            <div className="education-image-wrapper">
              <div
                className="education-bg education-logo-bg"
                style={{
                  background: education.colorSecondary
                    ? education.colorSecondary
                    : education.colorPrimary,
                }}
              ></div>
              <img
                className="school-logo"
                src={require(`../../images/schools_logos/${school
                  .replace(/ /g, '')
                  .toLowerCase()}.png`)}
                alt={`${school}-logo`}
                style={
                  education.logoheight
                    ? {
                        height: `${education.logoheight}%`,
                      }
                    : { width: `${education.logowidth}%` }
                }
              />
            </div>
          </div> 
          <div className="education-card-bottom">
            <div>
              <h3>{title}</h3>
              <h4>{location}</h4>
              <h4> {dateFrom} - {dateTo} </h4>
              <h2>{diploma}</h2>
            </div>
          </div>
        </div>
      </div>
      </Link>
  )
}

export default EducationCard

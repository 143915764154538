import React from 'react'
import './About.css'
import Typewriter from 'typewriter-effect'
import { Link } from 'react-scroll'
import { Fade } from 'react-reveal'
import Section from '../section/Section'
import Skills from '../skills/Skills'

const About = () => {
  const handleEmailClick = () => {
    window.location.href = 'mailto:Elkouryvouded@gmail.com';
  };

  return (
    <Section title="About" titre="A Propos">
      <div className="about-content">
        <Fade duration={1000}>
          <div className="about-text">
            {/* <h2>Who am I?</h2> */}
            <h2>Qui suis-je ?</h2>
            <p>
              {/* I'm Elkoury{' '} */}
              Je suis Elkoury{' '}
              <span role="img" aria-label="lightning">
              😊
              </span>{' '}
            </p>
            <p>
              <span role="img" aria-label="lightning">
                ⚡
              </span>{' '}
              {/* I'm a Mechatronics and Robotics engineer. */}
              Je suis ingénieur en Mécatronique et Robotique.
            </p>
            <div className="typewriter">
              <p className="typewriter-start">
                <span role="img" aria-label="lightning">
                  ⚡
                </span>{' '}
                {/* I enjoy */}
                J'aime
              </p>
              <Typewriter
                options={{
                  strings: [
                    // 'learning',
                    // 'exercise',
                    // 'mecanical design',
                    // 'solving problems',
                    // 'fixing bugs',
                    // 'meeting new people',
                    // 'good food',
                    // 'circuit design',
                    'jouer au foot',
                    'faire de course a pied',
                    'lire',
                    'voyager',
                    'apprendre',
                    'résoudre les problèmes',
                    'programmer',
                    'faire la conception électronique et mécanique',
                    'rencontrer de nouvelles personnes',
                    'la bonne nourriture',
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
              <p>.</p>
            </div>
            <p>
            {/* From a young age, I have been deeply fascinated by robots and 
            moving toys, which inspired my decision to major in Mechatronics 
            after completing my baccalaureate. Since then, I have actively 
            participated in captivating projects that enabled me to apply 
            my knowledge and creativity. Several years later, I embarked 
            on my career in this exciting field of Mechatronics and Robotics, 
            continuing my journey of innovation., I've created some{' '} */}
            <span role="img" aria-label="lightning">
                ⚡
            </span>{' '}
            Depuis ma plus tendre enfance, ma fascination pour les robots et les jouets a été le moteur de mon parcours.
            De manière ironique, cette passion m'a conduit à me spécialiser en Mécatronique et Robotique.
            Depuis lors, j'ai activement participé à des projets passionnants, mettant en œuvre mes connaissances et ma créativité.
            Mon périple professionnel dans ce domaine captivant a débuté il y a quelques années, au cours desquelles j'ai initié des projets
              , évolué dans des {' '}
              <Link
                className="textLink"
                to="experience"
                spy={true}
                smooth={true}
                duration={500}
              >
                {/* great places */}
                environnements exceptionnels
              </Link>
              {/* , and met some great people. */}
              , et collaboré avec des individus inspirants.
            </p>
            <p>
              <span role="img" aria-label="lightning">
                ⚡
              </span>{' '}
               {/* You can find me working with Robot Development, ROS,
               Python, 3D Design, Electrical Engineering, and Networking Engineering. */}
              Titulaire de deux diplômes en mécatronique de Sigma Clermont et de l'ENISO, avec une spécialisation en
              robotique et en conception de systèmes mécatroniques, j'ai su exceller dans ces domaines grâce à mes 
              années d'expérience, tout en acquérant de nouvelles compétences en gestion de projets.
            </p>
            <p>
              <span role="img" aria-label="lightning">
                ⚡
              </span>{' '}
               {/* You can find me working with Robot Development, ROS,
               Python, 3D Design, Electrical Engineering, and Networking Engineering. */}
               Je suis particulièrement attiré par l'innovation et les défis.
            </p>
            <p>
              <span role="img" aria-label="lightning">
                ⚡
              </span>{' '}
               {/* You can find me working with Robot Development, ROS,
               Python, 3D Design, Electrical Engineering, and Networking Engineering. */}
               Mes compétences, mon sens du relationnel, ma rigueur et ma détermination me permettent
               d'intégrer rapidement une équipe et de m'adapter facilement à de nouveaux projets.
               </p>
            {/* <p>
              <span role="img" aria-label="lightning">
                ⚡
              </span>{' '}
               You can find me working with Robot Development, ROS,
               Python, 3D Design, Electrical Engineering, and Networking Engineering.
            </p> */}
            <div className="detail_wraper">
                    <div className="detail_element"> <span role="img" aria-label="lightning">
                        📍
                      </span>{' '}
                      <p> Île-de-France, France</p>
                    </div>
                    <div className="detail_element">
                      <span role="img" aria-label="lightning">
                        📝
                      </span>{' '}
                      <a
                          href={require('../../documents/CV_Elkoury_VOUDED.pdf')}
                          download="CV_Elkoury_VOUDED"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <p> Mon CV</p>
                        </a>
                    </div >
                    <div className="detail_element" onClick={handleEmailClick} style={{ cursor: 'pointer' }}>
                      <span role="img" aria-label="lightning">
                        📧
                      </span>{' '}
                      <p> Elkouryvouded@gmail.com</p>
                    </div>
                    <div className="detail_element">
                      <span role="img" aria-label="lightning">
                        📞
                      </span>{' '}
                      <p> 0651171678</p>
                    </div>
                    <div className="detail_element">
                      <span role="img" aria-label="lightning">
                        🚗
                      </span>{' '}
                      <p> Permis B</p>
                  </div>
                  </div>
            </div>
        </Fade>
        <Skills />
      </div>
    </Section>
  )
}

export default About
